@import '../../../../scss/theme-bootstrap';

.virtual-events {
  border: 2px solid transparent;
  color: $color--black;
  display: flex;
  min-height: 100px;
  @include breakpoint($bp--large-up) {
    min-height: 250px;
  }
  &__image {
    width: 113px;
    min-width: 113px;
    margin-bottom: 46px;
    @include breakpoint($bp--large-up) {
      width: 222px;
      min-width: 222px;
    }
    &--img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }
  &__details {
    padding-#{$ldirection}: 25px;
    padding-#{$rdirection}: 10px;
    margin-bottom: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint($bp--large-up) {
      padding-#{$rdirection}: 0;
    }
    &--status {
      text-transform: uppercase;
      margin-bottom: 15px;
      line-height: get-line-height(14px, 18px);
      @include breakpoint($bp--large-up) {
        margin-bottom: 20px;
      }
    }
    &--bullet {
      float: #{$ldirection};
      margin-#{$rdirection}: 6px;
      margin-top: 3px;
      width: 11px;
      height: 11px;
      line-height: 18px;
      border-radius: 50%;
      background-color: $color--white;
      @include breakpoint($bp--large-up) {
        width: 18px;
        height: 18px;
        margin-#{$rdirection}: 12px;
        margin-top: 0;
      }
      .active & {
        background-color: $color--red;
      }
    }
    &--header {
      text-transform: uppercase;
      margin-bottom: 17px;
      font-size: 14px;
      line-height: get-line-height(14px, 16px);
      p {
        margin: 0;
      }
      @include breakpoint($bp--large-up) {
        margin-bottom: 22px;
        font-size: 24px;
        line-height: get-line-height(24px, 28px);
      }
    }
    &--cta {
      text-transform: uppercase;
      color: $color--black;
      a,
      a:link,
      a:visited {
        color: inherit;
      }
      a:hover {
        color: $color--link-grey;
      }
    }
  }
  .dark-theme & {
    color: $color--white;
    .virtual-events__details--cta {
      color: $color--white;
    }
  }
}
